import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import SlickSlider from "react-slick";
import React from 'react';
import s from './Slider.module.css'
import classnames from "classnames";
import davide from '../../assets/img/davide.jpg'
import michal from '../../assets/img/michal.jpg'
import vincent from '../../assets/img/vincent.jpg'

const Slider = () => {

    const cards = [
        {
            number: 1,
            name: "Davide R",
            location: "Milan, Italy",
            avatar: davide,
            text: 'The OERO INTERNATIONAL Sp z oo team was with us at every stage - from strategy developmentto its ' +
                'implementation. Their professionalism, attention to detail, and personalizedapproachmade our collaboration' +
                ' incredibly effective. Thank you for your contribution to oursuccess!',

        },
        {
            number: 2,
            name: "Vincent M",
            location: "Paris, France",
            avatar: vincent,
            text: 'Working with OERO INTERNATIONAL Sp z oo completely transformed our approach to business\n' +
                'management. Their unique perspective and innovative solutions helped us optimize our\n' +
                'processes and significantly increase our profit. I recommend them as a reliable partner\n' +
                'in business consulting.',
        },
        {
            number: 3,
            name: "Michal S",
            avatar: michal,
            location: "Warsaw, Poland",
            text: 'I turned to OERO INTERNATIONAL Sp z oo for help in optimizing our operational process,\n' +
                'and\n' +
                'the results exceeded all expectations. Their team not only provided effective solutions\n' +
                'but\n' +
                'also trained our staff on their proper application. Our business has become much more\n' +
                'productive. Thank you for your professionalism and support!',
        },
    ]

    const isMobile = () => {
        return window.innerWidth < 768
    }

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    };

    return (
        <div className={classnames("slick-container")}>

            <SlickSlider {...settings}>
                {cards.map((card) => <div className={s.review_card}>
                    <div className={s.reviewer}>
                        <div>
                            <img className={s.avatar} src={card.avatar} alt=""/>
                        </div>
                        <div>
                            <p className={s.name}>{card.name}</p>
                            <p className={s.location}>{card.location}</p>
                        </div>
                    </div>
                    <div className={s.text}>
                        {card.text}
                    </div>
                </div>)}
            </SlickSlider>

        </div>
    );
};

export default Slider;
