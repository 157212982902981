import React from 'react';
import s from './Footer.module.css'
import logo from '../../assets/img/Logo.svg'
import map from '../../assets/img/location.svg'
import mail from '../../assets/img/mail.svg'
import classnames from "classnames";

const Footer = () => {
    return (
        <div className={s.footer}>
            <div className={s.main_content}>
                <div className={classnames(s.block, s.between)}>
                    <img className={s.logo} src={logo} alt=""/>
                    <p className={s.company_name}>
                        OERO INTERNATIONAL <br/>
                        Spółka z ograniczoną <br/>
                        odpowiedzialnością
                    </p>
                </div>
                <div className={s.block}>
                    <div className={`${s.flex} ${s.mb}`}>
                        <img className={s.img} src={map} alt=""/>
                        <p className={s.text}>
                            Address: ul. Hoża 86 lok. 410, <br/>
                            00-682 Warszawa, Poland
                        </p>
                    </div>
                    <div className={s.flex}>
                        <img className={s.img} src={mail} alt=""/>
                        <p className={s.text}>
                            oerocoin.pl@gmail.com
                        </p>
                    </div>
                </div>
                <div className={s.block}>
                    <p className={`${s.text} ${s.mb}`}>KRS: 0001053852</p>
                    <p className={`${s.text} ${s.mb}`}>NIP: 7011161006</p>
                    <p className={`${s.text} ${s.mb}`}>Region: 52616290700000</p>
                    <p className={s.text}>Date of registration: 22.08.2023</p>
                </div>
            </div>
            <div className={s.copyright}>
                © 2024 OeroPL
            </div>
        </div>
    );
};

export default Footer;
