import React from 'react';
import s from './CustomButton.module.css'

const CustomButton = (props) => {
    return (
        <button
            className={`${s.button} ${props.isSubmit ? s.submit : ''}`}
        >
            {props.title}
        </button>
    );
};

export default CustomButton;
