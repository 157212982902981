import s from "./App.module.css"
import logo from "./assets/img/Logo.svg"
import avantgarde from "./assets/img/avantgarde.png"
import fastlane from "./assets/img/fastlane.png"
import golden_grid from "./assets/img/golden_grid.png"
import herrywood from "./assets/img/herrywood.png"
import sweety from "./assets/img/sweety.png"
import cara_indoors from "./assets/img/cara_indoors.png"
import ron_jones from "./assets/img/ron_jones.png"
import company_name from "./assets/img/company_name.svg"
import team from "./assets/img/collective.jpg"
import vincent from "./assets/img/vincent.jpg"
import davide from "./assets/img/davide.jpg"
import michal from "./assets/img/michal.jpg"
import CustomButton from "./components/CustomButton/CustomButton";
import Footer from "./components/Footer/Footer";
import { motion } from "framer-motion"
import Slider from "./components/Slider/Slider";

function App() {

    const images = [
        avantgarde,
        fastlane,
        golden_grid,
        herrywood,
        sweety,
        cara_indoors,
        ron_jones
    ]

    return (
        <div>
            <div className={s.main_screen}>
                <div className={s.container}>

                    <div className={s.logo_block}>
                        <img src={logo} alt=""/>
                    </div>
                    <div className={s.main_description_block}>
                        <div className={s.company_name}>
                            <motion.div initial={{ y: '+100%' }}
                                        animate={{ y: '0%' }}
                                        transition={{ ease: "easeInOut", duration: 0.6 }}
                            >
                                <img src={company_name} alt=""/>
                            </motion.div>
                        </div>

                        <div style={{height: '120px', overflow: 'hidden'}}>
                            <motion.div initial={{ y: '+150%' }}
                                        animate={{ y: '0%' }}
                                        transition={{ ease: "easeInOut", duration: 0.8 }}
                            >
                            <div className={s.main_text}>
                                Your reliable partner in the world of business consulting. We offer comprehensive solutions for
                                companies aiming to expand, optimize, and reach new heights in their endeavors. With our help,
                                your business will find the path to innovation and success.
                            </div>
                            </motion.div>
                        </div>

                        <div className={s.button_block}>
                            <CustomButton title={"Submit application"}/>
                        </div>
                    </div>
                    <div className={s.brands}>
                        <p>Large brands trust us</p>
                        <div className={s.brands_list}>
                            {
                                images.map((item, index) => {
                                    return (
                                        <div className={s.brand_img_wrapper}>
                                            <img className={s.brand_img} src={item} alt="" key={index}/>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className={s.offer_block}>
                <div className={s.container}>
                    <div className={s.offer_title}>
                        <span>We offer a variety of </span><span className={s.gradient}>services</span>
                    </div>
                    <div className={s.cards_list_block}>
                        <div className={s.card}>
                            <div className={s.card_background_one}></div>
                            <p className={s.card_title}>Business Consulting</p>
                            <p className={s.card_text}>
                                We offer a wide range of consulting services, including strategic planning, change
                                management, business process optimization, and much more. Our goal is to provide you
                                with solutions that deliver real benefits to your business.
                            </p>
                        </div>
                        <div className={s.card}>
                            <div className={s.card_background_two}></div>
                            <p className={s.card_title}>Innovation and Development</p>
                            <p className={s.card_text}>
                                In a world where constant change has become the norm, we help our clients stay ahead by
                                offering innovative approaches and solutions for business development.
                            </p>
                        </div>
                        <div className={s.card}>
                            <div className={s.card_background_three}></div>
                            <p className={s.card_title}>Customized Approach</p>
                            <p className={s.card_text}>
                                We understand that every business is unique. Therefore, we offer customized solutions
                                designed specifically to meet your specific needs and goals.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className={s.team_block}>
                <img src={team} alt=""/>
            </div>

            <div className={s.about_us}>
                <div className={s.container}>
                    <p className={s.about_text}>
                        At <span className={s.gradient}>OERO INTERNATIONAL</span>, we strive to be leaders in consulting
                        by providing high-quality services that
                        contribute to the sustainable development and growth of our clients. Our mission is to help
                        enterprises
                        achieve their full potential through innovation, strategic planning, and efficient management.
                    </p>
                </div>
            </div>

            <div className={s.reviews}>
                <div className={s.container}>
                    <p className={s.reviews_title}>
                        Reviews
                    </p>
                    <div className={s.reviews_list}>
                        <div className={s.review_card}>
                            <div className={s.reviewer}>
                                <div>
                                    <img className={s.avatar} src={vincent} alt=""/>
                                </div>
                                <div>
                                    <p className={s.name}>Vincent M</p>
                                    <p className={s.location}>Paris, France</p>
                                </div>
                            </div>
                            <div>
                                Working with OERO INTERNATIONAL Sp z oo completely transformed our approach to business
                                management. Their unique perspective and innovative solutions helped us optimize our
                                processes and significantly increase our profit. I recommend them as a reliable partner
                                in
                                business consulting.
                            </div>
                        </div>
                        <div className={s.review_card}>
                            <div className={s.reviewer}>
                                <div>
                                    <img className={s.avatar} src={davide} alt=""/>
                                </div>
                                <div>
                                    <p className={s.name}>Davide R</p>
                                    <p className={s.location}>Milan, Italy</p>
                                </div>
                            </div>
                            <div>
                                The OERO INTERNATIONAL Sp z oo team was with us at every stage - from strategy
                                development
                                to its implementation. Their professionalism, attention to detail, and personalized
                                approach
                                made our collaboration incredibly effective. Thank you for your contribution to our
                                success!
                            </div>
                        </div>
                        <div className={s.review_card}>
                            <div className={s.reviewer}>
                                <div>
                                    <img className={s.avatar} src={michal} alt=""/>
                                </div>
                                <div>
                                    <p className={s.name}>Michal S</p>
                                    <p className={s.location}>Warsaw, Poland</p>
                                </div>
                            </div>
                            <div>
                                I turned to OERO INTERNATIONAL Sp z oo for help in optimizing our operational process,
                                and
                                the results exceeded all expectations. Their team not only provided effective solutions
                                but
                                also trained our staff on their proper application. Our business has become much more
                                productive. Thank you for your professionalism and support!
                            </div>
                        </div>
                    </div>
                    <div className={s.slider_mobile}>
                        <Slider/>
                    </div>
                </div>

            </div>

            <div className={s.contact_us_wrapper}>
                <div className={s.contact_us_content}>
                    <div className={s.contact_title}>
                        Contact Us
                    </div>
                    <div className={s.contact_desc}>
                        We are always available to help you with any questions or provide additional information. Please
                        fill out the form below and our team will get back to you as soon as possible.
                    </div>
                    <form>
                        <div className={s.input_wrapper}>
                            <span className={s.label}>Your name</span>
                            <input type="text" className={s.my_input}/>
                        </div>
                        <div className={s.input_wrapper}>
                            <span className={s.label}>Your email</span>
                            <input type="text" className={s.my_input}/>
                        </div>
                        <div className={s.input_wrapper}>
                            <span className={s.label}>Phone (optional)</span>
                            <input type="text" className={s.my_input}/>
                        </div>
                        <div className={s.input_wrapper}>
                            <span className={s.label}>Your message</span>
                            <textarea className={`${s.my_input} ${s.my_textarea}`}/>
                        </div>
                        <div className={s.button_wrapper}>
                            <CustomButton title={'submit'} isSubmit={true}/>
                        </div>
                    </form>
                </div>
            </div>

            <div className={s.footer_wrapper}>
                <div className={s.container}>
                    <Footer/>
                </div>
            </div>

        </div>
    );
}

export default App;
